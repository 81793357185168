// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as hostAddressesApi from '@/services/host-addresses.js'
import * as hostsApi from '@/services/hosts.js'
import * as addressTypesApi from '@/services/address-types.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'

export default {
  components: {
    importImg
  },
  data () {
    return {
      constant,
      dataDictionary,
      verifyRules: verifyRules(this),
      urlParams: null,
      hostList: [],
      addressTypeList: [],
      mainForm: {
        fileData: {
          cover: []
        },
        data: {
          host_id: null,
          address_type_id: null,
          name: null,
          unit_no: null,
          block_number: null,
          building_name: null,
          postal_code: null,
          remarks: null,
          address: null,
          images_attributes: []
        }
      }
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    const initRequest1 = hostAddressesApi.getIdPreview(_this.urlParams.id).then(res => {
      const resData = res.data.address
      Object.assign(_this.mainForm.data, resData)
      _this.mainForm.data.images_attributes = []
      _this.mainForm.fileData.cover = []
      resData.images_attributes.forEach(item => {
        _this.mainForm.data.images_attributes.push({
          ...item,
          remote_image_url: item.image_url,
          _destroy: false
        })
        _this.mainForm.fileData.cover.push({
          id: item.id,
          image_url: item.image_url
        })
      })
      return res
    }).catch(err => err)
    const initRequest2 = hostsApi.getHostList().then(res => {
      _this.hostList = res.data.host_list
      return res
    }).catch(err => err)
    const initRequest3 = addressTypesApi.getAddressTypeList().then(res => {
      _this.addressTypeList = res.data.address_type_list
      return res
    }).catch(err => err)
    _this.setGlobalLoading(true)
    Promise.all([initRequest1, initRequest2, initRequest3]).then(() => {
      _this.setGlobalLoading(false)
    }).catch(() => {
      _this.setGlobalLoading(false)
    })
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainFormCoverChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0) {
        _this.mainForm.data.images_attributes = fileData.map(item => {
          return {
            id: item.id,
            remote_image_url: item.url,
            _destroy: !!item._destroy
          }
        })
      } else {
        _this.mainForm.data.images_attributes = []
      }
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            address: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          hostAddressesApi.putHostAddressesId(_this.urlParams.id, requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'buddyManagerBuddyDetail',
                params: {
                  id: this.$route.params.buddy
                }
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        }
      })
    }
  }
}
