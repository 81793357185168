<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.buddyManagerAddressAdd.mainForm" label-width="130px">
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 团长 -->
            <el-form-item :label="$t('text.buddy')" prop="host_id">
              <el-select v-model="mainForm.data.host_id" class="width-fill" :placeholder="$t('placeholder.defaultSelect')" clearable filterable>
                <el-option v-for="(item, index) in hostList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 地址类别 -->
            <el-form-item :label="$t('form.buddy.addressType')">
              <el-select v-model="mainForm.data.address_type_id" class="width-fill" :placeholder="$t('placeholder.defaultSelect')" clearable filterable>
                <el-option v-for="(item, index) in addressTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 名称 -->
            <el-form-item :label="$t('text.name')">
              <el-input v-model="mainForm.data.name" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 门牌号 -->
            <el-form-item :label="$t('form.buddy.houseNumber')">
              <el-input v-model="mainForm.data.unit_no" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- Block number -->
            <el-form-item :label="$t('form.buddy.blockNumber')">
              <el-input v-model="mainForm.data.block_number" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 写字楼 -->
            <el-form-item :label="$t('form.buddy.officeBuilding')">
              <el-input v-model="mainForm.data.building_name" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- Road Name -->
            <el-form-item :label="$t('form.buddy.roadName')">
              <el-input v-model="mainForm.data.address" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 邮编 -->
            <el-form-item :label="$t('form.buddy.zipCode')">
              <el-input v-model="mainForm.data.postal_code" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="24">
            <!-- 提货提示 -->
            <el-form-item :label="$t('form.buddy.deliveryPrompt')">
              <el-input v-model="mainForm.data.remarks" type="textarea" :placeholder="$t('placeholder.defaultInput')" :maxlength="constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="24">
            <!-- 图片 -->
            <el-form-item :label="$t('form.product.image')">
              <importImg :limit="100" :data="mainForm.fileData.cover" @change="handleMainFormCoverChange"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
